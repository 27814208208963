export default function Footer({

}) {
    return (
        <>
            design &amp; development by Frantisek Hajnovic<br/>
            copyright © 2020, all rights reserved <br/>
        </>
    );
  }
  